import validate from "/home/spectertrustcar5/public_html/cartrust_website/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/spectertrustcar5/public_html/cartrust_website/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/spectertrustcar5/public_html/cartrust_website/middleware/auth.ts"),
  "language-service": () => import("/home/spectertrustcar5/public_html/cartrust_website/middleware/languageService.ts"),
  "tamara-service": () => import("/home/spectertrustcar5/public_html/cartrust_website/middleware/tamaraService.ts")
}