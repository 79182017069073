// store/language.ts
import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import { useRouter } from "vue-router";

export const useLanguageStore = defineStore("language", () => {
  const selectedLanguage = ref(localStorage.getItem("selectedLanguage") || "ar");
  const isLoading = ref(false);
  const config = useRuntimeConfig();
  const cachedTranslations = ref<Record<string, any>>({});
  const router = useRouter(); // To react to URL changes

  // Retry logic for the fetch call
  const fetchWithRetry = async (url: string, retries: number = 3) => {
    let attempt = 0;
    while (attempt < retries) {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        if (response.ok) return await response.json();
        throw new Error(`HTTP error: ${response.status}`);
      } catch (error) {
        if (attempt === retries - 1) {
          console.error("Max retries reached:", error);
          throw error; // Re-throw error if max retries reached
        }
        attempt++;
        console.warn(`Retrying (${attempt})...`);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait before retrying
      }
    }
  };

  // Function to change language
  const changeLanguage = async (lang: string) => {
    // Validate the language code
    const validLanguages = ['en', 'ar']; // Add all supported languages here
    if (!validLanguages.includes(lang)) {
      console.error(`Invalid language code: ${lang}`);
      return;
    }

    selectedLanguage.value = lang;
    localStorage.setItem("selectedLanguage", lang);

    // Check for cached translations
    if (cachedTranslations.value[lang]) {
      return cachedTranslations.value[lang]; // Return cached translations if available
    }

    isLoading.value = true;

    try {
      console.log("Fetching language labels...");
      const data = await fetchWithRetry(
        `${config.public.cmsApiURL}/language-labels?locale=${lang}&pagination[pageSize]=1000`
      );
      
      if (data?.data) {
        const translations = data.data.reduce((acc: any, item: any) => {
          acc[item.label_key] = item.text;
          return acc;
        }, {});
        cachedTranslations.value[lang] = translations;
        return translations;
      }
    } catch (error) {
      console.error("Error fetching language labels:", error); // Log any errors
    } finally {
      isLoading.value = false;
    }
    return null;
  };

  // Watch URL for changes and set the language accordingly
  const setLanguageFromUrl = async () => {
    await nextTick(); // Ensure the app is fully loaded before setting the language
    const currentLanguage = window.location.pathname.split("/")[1];
      if (currentLanguage && currentLanguage !== selectedLanguage.value) {
      if (['en', 'ar'].includes(currentLanguage)) {
        // Valid language code (add more as needed)
        await changeLanguage(currentLanguage);
      } else {
        console.error(`Invalid language code from URL: ${currentLanguage}`);
      }
    }
  };

  // Call this method initially on app load
  setLanguageFromUrl();

  return { selectedLanguage, isLoading, cachedTranslations, changeLanguage };
});
