
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_93ePfv01KKSNMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/[...slug].vue?macro=true";
import { default as About1HeroE1ZSkHeANJMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/About1Hero.vue?macro=true";
import { default as Awards8eueCyLmEKMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Awards.vue?macro=true";
import { default as BenefitsP9iOdO4y9QMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Benefits.vue?macro=true";
import { default as ContactjaQFmCpWYnMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Contact.vue?macro=true";
import { default as SocialNetworkzTBQ5SPb9MMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/SocialNetwork.vue?macro=true";
import { default as TeamnZAv27ozEjMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Team.vue?macro=true";
import { default as TeamCardOw6Uh6iCtNMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TeamCard.vue?macro=true";
import { default as TestimonialQt3PeIKfmBMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Testimonial.vue?macro=true";
import { default as TestimonialCardqsvFEcAGHXMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TestimonialCard.vue?macro=true";
import { default as dataqNqvNzVPRgMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/data.ts?macro=true";
import { default as index7UdGzcRUZBMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/about/index.vue?macro=true";
import { default as indexQmvlwF0z2kMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/branches/index.vue?macro=true";
import { default as indexQQdi0Yf6VGMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cancel/index.vue?macro=true";
import { default as index5W2VFvUhn4Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/career/index.vue?macro=true";
import { default as FaqslnS3bjAQzOMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Faqs.vue?macro=true";
import { default as FeaturesIhBzDBcdoOMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Features.vue?macro=true";
import { default as OpportunitiesM77jkL2zrSMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Opportunities.vue?macro=true";
import { default as PlanComparisonHNsJjjGTj5Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/PlanComparison.vue?macro=true";
import { default as Pricing0NlIQgZIUmMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Pricing.vue?macro=true";
import { default as Saas3HerofVY4V86EjcMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Saas3Hero.vue?macro=true";
import { default as dataxVGOOcPXMEMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/data.ts?macro=true";
import { default as indexzMrdJ1NSISMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/index.vue?macro=true";
import { default as indexAxzJkfTvYQMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/checkout/index.vue?macro=true";
import { default as Contacts1HeroQ02F5vSxXbMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Contacts1Hero.vue?macro=true";
import { default as DetailsbbkBkGZsiCMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Details.vue?macro=true";
import { default as indexxXzmV6jAtJMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/contact/index.vue?macro=true";
import { default as indexZbVQ9AtMaTMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/coupons/index.vue?macro=true";
import { default as indexVnfqjfGWwSMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/index.vue?macro=true";
import { default as inspectionsG41yIGkCK0Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/inspections.vue?macro=true";
import { default as indexu2NvnsRyIRMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/profile/index.vue?macro=true";
import { default as transactionXfgzLFeE9hMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/transaction.vue?macro=true";
import { default as indexqDQyWYhcNPMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/user-scan-list/index.vue?macro=true";
import { default as vehiclestbbhFSi68VMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/vehicles.vue?macro=true";
import { default as walletgY9CgsOcedMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/wallet.vue?macro=true";
import { default as warrantiesHaRs2b9ZjuMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/warranties.vue?macro=true";
import { default as Contentf7orHeS7TFMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/components/Content.vue?macro=true";
import { default as indexTC15A2MB4OMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/index.vue?macro=true";
import { default as BrandsFL3lJ9i0XXMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Brands.vue?macro=true";
import { default as CarSearchBoxyfzV36iQmlMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/CarSearchBox.vue?macro=true";
import { default as FinancialHeroAL89cmdd4RMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/FinancialHero.vue?macro=true";
import { default as PricingXDSkvuNARKMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Pricing.vue?macro=true";
import { default as ProcessSwiperzEexxJ8WvgMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/ProcessSwiper.vue?macro=true";
import { default as SponsorsQFZfa0UjQ4Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Sponsors.vue?macro=true";
import { default as dataglUoMsyTNaMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/data.ts?macro=true";
import { default as indexHQKjBwSYPAMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/index.vue?macro=true";
import { default as indexTwW4wlGNgjMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/[id]/index.vue?macro=true";
import { default as feedbackiWY2xsn3tFMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/feedback.vue?macro=true";
import { default as ReEvaluation5qVLTczczFMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/ReEvaluation.vue?macro=true";
import { default as indexnvyidpwdLXMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/index.vue?macro=true";
import { default as indexuSaInFtjB7Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/fail/index.vue?macro=true";
import { default as Contacts1HeroCVx31VklqKMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Contacts1Hero.vue?macro=true";
import { default as FaqsxRzTrtjGKqMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Faqs.vue?macro=true";
import { default as indexgbYO9sS11VMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/faq/index.vue?macro=true";
import { default as HeroSection9dZ4O5Se7zMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/HeroSection.vue?macro=true";
import { default as SearchCarDMvAUXsxbxMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/SearchCar.vue?macro=true";
import { default as Specifications8eUgensp3EMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/Specifications.vue?macro=true";
import { default as indexoReuztkjKJMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/index.vue?macro=true";
import { default as Contentcn7iHqaT74Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/Content.vue?macro=true";
import { default as SinglePostHeroPhMYWvV9uiMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/SinglePostHero.vue?macro=true";
import { default as indexcwxzkSQZaLMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/index.vue?macro=true";
import { default as indexjSTrZMeXQyMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/index.vue?macro=true";
import { default as CarSearchBoxVfkcU05ZCqMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/CarSearchBox.vue?macro=true";
import { default as ComparisonSliderXUkq8nH7Y6Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/ComparisonSlider.vue?macro=true";
import { default as Credits7OAOxg3OVZMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Credits.vue?macro=true";
import { default as FeaturesgvmeCwO3u1Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Features.vue?macro=true";
import { default as InspectionTypesBG9Pblr8xeMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/InspectionTypes.vue?macro=true";
import { default as Saas1HeroYvqlF67DRFMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Saas1Hero.vue?macro=true";
import { default as WorkagUntatyojMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Work.vue?macro=true";
import { default as data5uydhSbtzdMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/data.ts?macro=true";
import { default as indexVX4G4X6CvjMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/index.vue?macro=true";
import { default as indexu6euw4qC1NMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/[id]/index.vue?macro=true";
import { default as index5zrVCvg8vtMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/index.vue?macro=true";
import { default as imageN4Du71zeYqMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/assets/data/image.ts?macro=true";
import { default as AnchorNavigationJwmHFAQlv1Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue?macro=true";
import { default as BreadcrumbmktweM6YiVMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue?macro=true";
import { default as CustomSyntaxChIyeslJVNMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue?macro=true";
import { default as Jarallaxvs0iW8XJ4FMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue?macro=true";
import { default as LightGalleryMKXc4B5GcZMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue?macro=true";
import { default as LoadingSpinnerZ8AxBX6iHBMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue?macro=true";
import { default as ParallaxK7H4zzSawZMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue?macro=true";
import { default as PasswordInputgH5s4ncIVJMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue?macro=true";
import { default as RellaxzJF1Mhzv3JMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue?macro=true";
import { default as VanillaTilt6u1vdRRl44Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue?macro=true";
import { default as VideoPopupoJxUTvLnAiMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue?macro=true";
import { default as ArticleCardlb4UQHm2ClMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/ArticleCard.vue?macro=true";
import { default as ArticlesDEMGsbBNOUMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Articles.vue?macro=true";
import { default as CommentxMrZ0nQ5iYMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Comment.vue?macro=true";
import { default as CommentFormNPrZLSCTF6Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/CommentForm.vue?macro=true";
import { default as Content8jG6ISEwYZMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Content.vue?macro=true";
import { default as JarallaxRrM0Yb2JbKMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Jarallax.vue?macro=true";
import { default as LightGallerykaPcM25ks5Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/LightGallery.vue?macro=true";
import { default as SinglePostFooterC8PVZRM6rTMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostFooter.vue?macro=true";
import { default as SinglePostHerow7kX9rANUkMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostHero.vue?macro=true";
import { default as dataJ9Ut0ubtjBMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/data.ts?macro=true";
import { default as indexnvfZB5YS9eMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/index.vue?macro=true";
import { default as BlogCardhxG5lw16lLMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/BlogCard.vue?macro=true";
import { default as ContentpGXZoSWYeQMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Content.vue?macro=true";
import { default as FooterZY1lfZXzb1Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Footer.vue?macro=true";
import { default as PaginationRNXIwqJDpUMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Pagination.vue?macro=true";
import { default as datalbsD9HsPHSMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/data.ts?macro=true";
import { default as index1mo0kUSEh3Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/index.vue?macro=true";
import { default as single_45newsp7e5Henz1ZMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/news/single-news.vue?macro=true";
import { default as indexttQBWN6t9FMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/our-clients/index.vue?macro=true";
import { default as indexI0cHJXCwvYMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/partners/index.vue?macro=true";
import { default as indexvkASTwJpphMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/payment/redirect/index.vue?macro=true";
import { default as Contentzl9AsxQNzfMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/Content.vue?macro=true";
import { default as SinglePostHeroPbce9uuSvcMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/SinglePostHero.vue?macro=true";
import { default as index3Yy8Mp8rERMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/index.vue?macro=true";
import { default as index2ZjTeMUjTGMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/sales-agent-form/index.vue?macro=true";
import { default as indexRfywvKE7KJMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/success/index.vue?macro=true";
import { default as indext3NlonbZrkMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/survey/index.vue?macro=true";
import { default as Content3uDv10Or87Meta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/components/Content.vue?macro=true";
import { default as indexMqaIJBSmczMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/index.vue?macro=true";
import { default as indexNLDnHE4iCbMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/trailer-inspection-and-half-trailers/index.vue?macro=true";
import { default as indexZZsCGZGKjgMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/trim/index.vue?macro=true";
import { default as indexFcjl9tXDExMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/vin-decoder/index.vue?macro=true";
import { default as indexDO95Zu8VhLMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranties/index.vue?macro=true";
import { default as PricingqZN3YJ0ezWMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Pricing.vue?macro=true";
import { default as PricingForMobileT0Q33nZ6DkMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/PricingForMobile.vue?macro=true";
import { default as Saas3HerovhROmTXyhDMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Saas3Hero.vue?macro=true";
import { default as WarrantyFormYLTS7kIdPdMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/WarrantyForm.vue?macro=true";
import { default as indexVBskuRoFOlMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/index.vue?macro=true";
import { default as indexzvvhucekIiMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form/index.vue?macro=true";
import { default as CarSearchBoxlwWGdIDDBFMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/CarSearchBox.vue?macro=true";
import { default as PricingWW3XlsMeNeMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Pricing.vue?macro=true";
import { default as PricingForMobileqmJI8Y6RZAMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/PricingForMobile.vue?macro=true";
import { default as Saas3HerootfqCY9gTzMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Saas3Hero.vue?macro=true";
import { default as WarrantyFeaturezpC9kzwdWLMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/WarrantyFeature.vue?macro=true";
import { default as indexVh6E3E8qOJMeta } from "/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/index.vue?macro=true";
import { default as component_45stubrYY2lHF0KfMeta } from "/home/spectertrustcar5/public_html/cartrust_website/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrYY2lHF0Kf } from "/home/spectertrustcar5/public_html/cartrust_website/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93ePfv01KKSNMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/[...slug].vue")
  },
  {
    name: "slug___ar___default",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93ePfv01KKSNMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/[...slug].vue")
  },
  {
    name: "slug___ar",
    path: "/ar/:slug(.*)*",
    meta: _91_46_46_46slug_93ePfv01KKSNMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/[...slug].vue")
  },
  {
    name: "about-components-About1Hero___en",
    path: "/en/about/components/About1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/About1Hero.vue")
  },
  {
    name: "about-components-About1Hero___ar___default",
    path: "/about/components/About1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/About1Hero.vue")
  },
  {
    name: "about-components-About1Hero___ar",
    path: "/ar/about/components/About1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/About1Hero.vue")
  },
  {
    name: "about-components-Awards___en",
    path: "/en/about/components/Awards",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Awards.vue")
  },
  {
    name: "about-components-Awards___ar___default",
    path: "/about/components/Awards",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Awards.vue")
  },
  {
    name: "about-components-Awards___ar",
    path: "/ar/about/components/Awards",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Awards.vue")
  },
  {
    name: "about-components-Benefits___en",
    path: "/en/about/components/Benefits",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Benefits.vue")
  },
  {
    name: "about-components-Benefits___ar___default",
    path: "/about/components/Benefits",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Benefits.vue")
  },
  {
    name: "about-components-Benefits___ar",
    path: "/ar/about/components/Benefits",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Benefits.vue")
  },
  {
    name: "about-components-Contact___en",
    path: "/en/about/components/Contact",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Contact.vue")
  },
  {
    name: "about-components-Contact___ar___default",
    path: "/about/components/Contact",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Contact.vue")
  },
  {
    name: "about-components-Contact___ar",
    path: "/ar/about/components/Contact",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Contact.vue")
  },
  {
    name: "about-components-SocialNetwork___en",
    path: "/en/about/components/SocialNetwork",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/SocialNetwork.vue")
  },
  {
    name: "about-components-SocialNetwork___ar___default",
    path: "/about/components/SocialNetwork",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/SocialNetwork.vue")
  },
  {
    name: "about-components-SocialNetwork___ar",
    path: "/ar/about/components/SocialNetwork",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/SocialNetwork.vue")
  },
  {
    name: "about-components-Team___en",
    path: "/en/about/components/Team",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Team.vue")
  },
  {
    name: "about-components-Team___ar___default",
    path: "/about/components/Team",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Team.vue")
  },
  {
    name: "about-components-Team___ar",
    path: "/ar/about/components/Team",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Team.vue")
  },
  {
    name: "about-components-TeamCard___en",
    path: "/en/about/components/TeamCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TeamCard.vue")
  },
  {
    name: "about-components-TeamCard___ar___default",
    path: "/about/components/TeamCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TeamCard.vue")
  },
  {
    name: "about-components-TeamCard___ar",
    path: "/ar/about/components/TeamCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TeamCard.vue")
  },
  {
    name: "about-components-Testimonial___en",
    path: "/en/about/components/Testimonial",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Testimonial.vue")
  },
  {
    name: "about-components-Testimonial___ar___default",
    path: "/about/components/Testimonial",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Testimonial.vue")
  },
  {
    name: "about-components-Testimonial___ar",
    path: "/ar/about/components/Testimonial",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/Testimonial.vue")
  },
  {
    name: "about-components-TestimonialCard___en",
    path: "/en/about/components/TestimonialCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TestimonialCard.vue")
  },
  {
    name: "about-components-TestimonialCard___ar___default",
    path: "/about/components/TestimonialCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TestimonialCard.vue")
  },
  {
    name: "about-components-TestimonialCard___ar",
    path: "/ar/about/components/TestimonialCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/components/TestimonialCard.vue")
  },
  {
    name: "about-data___en",
    path: "/en/about/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/data.ts")
  },
  {
    name: "about-data___ar___default",
    path: "/about/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/data.ts")
  },
  {
    name: "about-data___ar",
    path: "/ar/about/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/data.ts")
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: index7UdGzcRUZBMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/index.vue")
  },
  {
    name: "about___ar___default",
    path: "/about",
    meta: index7UdGzcRUZBMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/index.vue")
  },
  {
    name: "about___ar",
    path: "/ar/about",
    meta: index7UdGzcRUZBMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/about/index.vue")
  },
  {
    name: "branches___en",
    path: "/en/branches",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/branches/index.vue")
  },
  {
    name: "branches___ar___default",
    path: "/branches",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/branches/index.vue")
  },
  {
    name: "branches___ar",
    path: "/ar/branches",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/branches/index.vue")
  },
  {
    name: "cancel___en",
    path: "/en/cancel",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cancel/index.vue")
  },
  {
    name: "cancel___ar___default",
    path: "/cancel",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cancel/index.vue")
  },
  {
    name: "cancel___ar",
    path: "/ar/cancel",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cancel/index.vue")
  },
  {
    name: "career___en",
    path: "/en/career",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/career/index.vue")
  },
  {
    name: "career___ar___default",
    path: "/career",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/career/index.vue")
  },
  {
    name: "career___ar",
    path: "/ar/career",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/career/index.vue")
  },
  {
    name: "cartrust-agent-components-Faqs___en",
    path: "/en/cartrust-agent/components/Faqs",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Faqs.vue")
  },
  {
    name: "cartrust-agent-components-Faqs___ar___default",
    path: "/cartrust-agent/components/Faqs",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Faqs.vue")
  },
  {
    name: "cartrust-agent-components-Faqs___ar",
    path: "/ar/cartrust-agent/components/Faqs",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Faqs.vue")
  },
  {
    name: "cartrust-agent-components-Features___en",
    path: "/en/cartrust-agent/components/Features",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Features.vue")
  },
  {
    name: "cartrust-agent-components-Features___ar___default",
    path: "/cartrust-agent/components/Features",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Features.vue")
  },
  {
    name: "cartrust-agent-components-Features___ar",
    path: "/ar/cartrust-agent/components/Features",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Features.vue")
  },
  {
    name: "cartrust-agent-components-Opportunities___en",
    path: "/en/cartrust-agent/components/Opportunities",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Opportunities.vue")
  },
  {
    name: "cartrust-agent-components-Opportunities___ar___default",
    path: "/cartrust-agent/components/Opportunities",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Opportunities.vue")
  },
  {
    name: "cartrust-agent-components-Opportunities___ar",
    path: "/ar/cartrust-agent/components/Opportunities",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Opportunities.vue")
  },
  {
    name: "cartrust-agent-components-PlanComparison___en",
    path: "/en/cartrust-agent/components/PlanComparison",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/PlanComparison.vue")
  },
  {
    name: "cartrust-agent-components-PlanComparison___ar___default",
    path: "/cartrust-agent/components/PlanComparison",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/PlanComparison.vue")
  },
  {
    name: "cartrust-agent-components-PlanComparison___ar",
    path: "/ar/cartrust-agent/components/PlanComparison",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/PlanComparison.vue")
  },
  {
    name: "cartrust-agent-components-Pricing___en",
    path: "/en/cartrust-agent/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Pricing.vue")
  },
  {
    name: "cartrust-agent-components-Pricing___ar___default",
    path: "/cartrust-agent/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Pricing.vue")
  },
  {
    name: "cartrust-agent-components-Pricing___ar",
    path: "/ar/cartrust-agent/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Pricing.vue")
  },
  {
    name: "cartrust-agent-components-Saas3Hero___en",
    path: "/en/cartrust-agent/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Saas3Hero.vue")
  },
  {
    name: "cartrust-agent-components-Saas3Hero___ar___default",
    path: "/cartrust-agent/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Saas3Hero.vue")
  },
  {
    name: "cartrust-agent-components-Saas3Hero___ar",
    path: "/ar/cartrust-agent/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/components/Saas3Hero.vue")
  },
  {
    name: "cartrust-agent-data___en",
    path: "/en/cartrust-agent/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/data.ts")
  },
  {
    name: "cartrust-agent-data___ar___default",
    path: "/cartrust-agent/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/data.ts")
  },
  {
    name: "cartrust-agent-data___ar",
    path: "/ar/cartrust-agent/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/data.ts")
  },
  {
    name: "cartrust-agent___en",
    path: "/en/cartrust-agent",
    meta: indexzMrdJ1NSISMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/index.vue")
  },
  {
    name: "cartrust-agent___ar___default",
    path: "/cartrust-agent",
    meta: indexzMrdJ1NSISMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/index.vue")
  },
  {
    name: "cartrust-agent___ar",
    path: "/ar/cartrust-agent",
    meta: indexzMrdJ1NSISMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/cartrust-agent/index.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/checkout/index.vue")
  },
  {
    name: "checkout___ar___default",
    path: "/checkout",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/checkout/index.vue")
  },
  {
    name: "checkout___ar",
    path: "/ar/checkout",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/checkout/index.vue")
  },
  {
    name: "contact-components-Contacts1Hero___en",
    path: "/en/contact/components/Contacts1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Contacts1Hero.vue")
  },
  {
    name: "contact-components-Contacts1Hero___ar___default",
    path: "/contact/components/Contacts1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Contacts1Hero.vue")
  },
  {
    name: "contact-components-Contacts1Hero___ar",
    path: "/ar/contact/components/Contacts1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Contacts1Hero.vue")
  },
  {
    name: "contact-components-Details___en",
    path: "/en/contact/components/Details",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Details.vue")
  },
  {
    name: "contact-components-Details___ar___default",
    path: "/contact/components/Details",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Details.vue")
  },
  {
    name: "contact-components-Details___ar",
    path: "/ar/contact/components/Details",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/components/Details.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: indexxXzmV6jAtJMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/index.vue")
  },
  {
    name: "contact___ar___default",
    path: "/contact",
    meta: indexxXzmV6jAtJMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/index.vue")
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    meta: indexxXzmV6jAtJMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/contact/index.vue")
  },
  {
    name: "dashboard-coupons___en",
    path: "/en/dashboard/coupons",
    meta: indexZbVQ9AtMaTMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/coupons/index.vue")
  },
  {
    name: "dashboard-coupons___ar___default",
    path: "/dashboard/coupons",
    meta: indexZbVQ9AtMaTMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/coupons/index.vue")
  },
  {
    name: "dashboard-coupons___ar",
    path: "/ar/dashboard/coupons",
    meta: indexZbVQ9AtMaTMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/coupons/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    meta: indexVnfqjfGWwSMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___ar___default",
    path: "/dashboard",
    meta: indexVnfqjfGWwSMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___ar",
    path: "/ar/dashboard",
    meta: indexVnfqjfGWwSMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-inspections___en",
    path: "/en/dashboard/inspections",
    meta: inspectionsG41yIGkCK0Meta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/inspections.vue")
  },
  {
    name: "dashboard-inspections___ar___default",
    path: "/dashboard/inspections",
    meta: inspectionsG41yIGkCK0Meta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/inspections.vue")
  },
  {
    name: "dashboard-inspections___ar",
    path: "/ar/dashboard/inspections",
    meta: inspectionsG41yIGkCK0Meta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/inspections.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "/en/dashboard/profile",
    meta: indexu2NvnsRyIRMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-profile___ar___default",
    path: "/dashboard/profile",
    meta: indexu2NvnsRyIRMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-profile___ar",
    path: "/ar/dashboard/profile",
    meta: indexu2NvnsRyIRMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-transaction___en",
    path: "/en/dashboard/transaction",
    meta: transactionXfgzLFeE9hMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/transaction.vue")
  },
  {
    name: "dashboard-transaction___ar___default",
    path: "/dashboard/transaction",
    meta: transactionXfgzLFeE9hMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/transaction.vue")
  },
  {
    name: "dashboard-transaction___ar",
    path: "/ar/dashboard/transaction",
    meta: transactionXfgzLFeE9hMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/transaction.vue")
  },
  {
    name: "dashboard-user-scan-list___en",
    path: "/en/dashboard/user-scan-list",
    meta: indexqDQyWYhcNPMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/user-scan-list/index.vue")
  },
  {
    name: "dashboard-user-scan-list___ar___default",
    path: "/dashboard/user-scan-list",
    meta: indexqDQyWYhcNPMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/user-scan-list/index.vue")
  },
  {
    name: "dashboard-user-scan-list___ar",
    path: "/ar/dashboard/user-scan-list",
    meta: indexqDQyWYhcNPMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/user-scan-list/index.vue")
  },
  {
    name: "dashboard-vehicles___en",
    path: "/en/dashboard/vehicles",
    meta: vehiclestbbhFSi68VMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/vehicles.vue")
  },
  {
    name: "dashboard-vehicles___ar___default",
    path: "/dashboard/vehicles",
    meta: vehiclestbbhFSi68VMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/vehicles.vue")
  },
  {
    name: "dashboard-vehicles___ar",
    path: "/ar/dashboard/vehicles",
    meta: vehiclestbbhFSi68VMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/vehicles.vue")
  },
  {
    name: "dashboard-wallet___en",
    path: "/en/dashboard/wallet",
    meta: walletgY9CgsOcedMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/wallet.vue")
  },
  {
    name: "dashboard-wallet___ar___default",
    path: "/dashboard/wallet",
    meta: walletgY9CgsOcedMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/wallet.vue")
  },
  {
    name: "dashboard-wallet___ar",
    path: "/ar/dashboard/wallet",
    meta: walletgY9CgsOcedMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/wallet.vue")
  },
  {
    name: "dashboard-warranties___en",
    path: "/en/dashboard/warranties",
    meta: warrantiesHaRs2b9ZjuMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/warranties.vue")
  },
  {
    name: "dashboard-warranties___ar___default",
    path: "/dashboard/warranties",
    meta: warrantiesHaRs2b9ZjuMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/warranties.vue")
  },
  {
    name: "dashboard-warranties___ar",
    path: "/ar/dashboard/warranties",
    meta: warrantiesHaRs2b9ZjuMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/dashboard/warranties.vue")
  },
  {
    name: "data-products-disclaimer-components-Content___en",
    path: "/en/data-products-disclaimer/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/components/Content.vue")
  },
  {
    name: "data-products-disclaimer-components-Content___ar___default",
    path: "/data-products-disclaimer/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/components/Content.vue")
  },
  {
    name: "data-products-disclaimer-components-Content___ar",
    path: "/ar/data-products-disclaimer/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/components/Content.vue")
  },
  {
    name: "data-products-disclaimer___en",
    path: "/en/data-products-disclaimer",
    meta: indexTC15A2MB4OMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/index.vue")
  },
  {
    name: "data-products-disclaimer___ar___default",
    path: "/data-products-disclaimer",
    meta: indexTC15A2MB4OMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/index.vue")
  },
  {
    name: "data-products-disclaimer___ar",
    path: "/ar/data-products-disclaimer",
    meta: indexTC15A2MB4OMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/data-products-disclaimer/index.vue")
  },
  {
    name: "evaluation-components-Brands___en",
    path: "/en/evaluation/components/Brands",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Brands.vue")
  },
  {
    name: "evaluation-components-Brands___ar___default",
    path: "/evaluation/components/Brands",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Brands.vue")
  },
  {
    name: "evaluation-components-Brands___ar",
    path: "/ar/evaluation/components/Brands",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Brands.vue")
  },
  {
    name: "evaluation-components-CarSearchBox___en",
    path: "/en/evaluation/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/CarSearchBox.vue")
  },
  {
    name: "evaluation-components-CarSearchBox___ar___default",
    path: "/evaluation/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/CarSearchBox.vue")
  },
  {
    name: "evaluation-components-CarSearchBox___ar",
    path: "/ar/evaluation/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/CarSearchBox.vue")
  },
  {
    name: "evaluation-components-FinancialHero___en",
    path: "/en/evaluation/components/FinancialHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/FinancialHero.vue")
  },
  {
    name: "evaluation-components-FinancialHero___ar___default",
    path: "/evaluation/components/FinancialHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/FinancialHero.vue")
  },
  {
    name: "evaluation-components-FinancialHero___ar",
    path: "/ar/evaluation/components/FinancialHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/FinancialHero.vue")
  },
  {
    name: "evaluation-components-Pricing___en",
    path: "/en/evaluation/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Pricing.vue")
  },
  {
    name: "evaluation-components-Pricing___ar___default",
    path: "/evaluation/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Pricing.vue")
  },
  {
    name: "evaluation-components-Pricing___ar",
    path: "/ar/evaluation/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Pricing.vue")
  },
  {
    name: "evaluation-components-ProcessSwiper___en",
    path: "/en/evaluation/components/ProcessSwiper",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/ProcessSwiper.vue")
  },
  {
    name: "evaluation-components-ProcessSwiper___ar___default",
    path: "/evaluation/components/ProcessSwiper",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/ProcessSwiper.vue")
  },
  {
    name: "evaluation-components-ProcessSwiper___ar",
    path: "/ar/evaluation/components/ProcessSwiper",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/ProcessSwiper.vue")
  },
  {
    name: "evaluation-components-Sponsors___en",
    path: "/en/evaluation/components/Sponsors",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Sponsors.vue")
  },
  {
    name: "evaluation-components-Sponsors___ar___default",
    path: "/evaluation/components/Sponsors",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Sponsors.vue")
  },
  {
    name: "evaluation-components-Sponsors___ar",
    path: "/ar/evaluation/components/Sponsors",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/components/Sponsors.vue")
  },
  {
    name: "evaluation-data___en",
    path: "/en/evaluation/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/data.ts")
  },
  {
    name: "evaluation-data___ar___default",
    path: "/evaluation/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/data.ts")
  },
  {
    name: "evaluation-data___ar",
    path: "/ar/evaluation/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/data.ts")
  },
  {
    name: "evaluation___en",
    path: "/en/evaluation",
    meta: indexHQKjBwSYPAMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/index.vue")
  },
  {
    name: "evaluation___ar___default",
    path: "/evaluation",
    meta: indexHQKjBwSYPAMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/index.vue")
  },
  {
    name: "evaluation___ar",
    path: "/ar/evaluation",
    meta: indexHQKjBwSYPAMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluation/index.vue")
  },
  {
    name: "evaluations-id___en",
    path: "/en/evaluations/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/[id]/index.vue")
  },
  {
    name: "evaluations-id___ar___default",
    path: "/evaluations/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/[id]/index.vue")
  },
  {
    name: "evaluations-id___ar",
    path: "/ar/evaluations/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/[id]/index.vue")
  },
  {
    name: "evaluations-Components-feedback___en",
    path: "/en/evaluations/Components/feedback",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/feedback.vue")
  },
  {
    name: "evaluations-Components-feedback___ar___default",
    path: "/evaluations/Components/feedback",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/feedback.vue")
  },
  {
    name: "evaluations-Components-feedback___ar",
    path: "/ar/evaluations/Components/feedback",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/feedback.vue")
  },
  {
    name: "evaluations-Components-ReEvaluation___en",
    path: "/en/evaluations/Components/ReEvaluation",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/ReEvaluation.vue")
  },
  {
    name: "evaluations-Components-ReEvaluation___ar___default",
    path: "/evaluations/Components/ReEvaluation",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/ReEvaluation.vue")
  },
  {
    name: "evaluations-Components-ReEvaluation___ar",
    path: "/ar/evaluations/Components/ReEvaluation",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/Components/ReEvaluation.vue")
  },
  {
    name: "evaluations___en",
    path: "/en/evaluations",
    meta: indexnvyidpwdLXMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/index.vue")
  },
  {
    name: "evaluations___ar___default",
    path: "/evaluations",
    meta: indexnvyidpwdLXMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/index.vue")
  },
  {
    name: "evaluations___ar",
    path: "/ar/evaluations",
    meta: indexnvyidpwdLXMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/evaluations/index.vue")
  },
  {
    name: "fail___en",
    path: "/en/fail",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/fail/index.vue")
  },
  {
    name: "fail___ar___default",
    path: "/fail",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/fail/index.vue")
  },
  {
    name: "fail___ar",
    path: "/ar/fail",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/fail/index.vue")
  },
  {
    name: "faq-components-Contacts1Hero___en",
    path: "/en/faq/components/Contacts1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Contacts1Hero.vue")
  },
  {
    name: "faq-components-Contacts1Hero___ar___default",
    path: "/faq/components/Contacts1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Contacts1Hero.vue")
  },
  {
    name: "faq-components-Contacts1Hero___ar",
    path: "/ar/faq/components/Contacts1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Contacts1Hero.vue")
  },
  {
    name: "faq-components-Faqs___en",
    path: "/en/faq/components/Faqs",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Faqs.vue")
  },
  {
    name: "faq-components-Faqs___ar___default",
    path: "/faq/components/Faqs",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Faqs.vue")
  },
  {
    name: "faq-components-Faqs___ar",
    path: "/ar/faq/components/Faqs",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/components/Faqs.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    meta: indexgbYO9sS11VMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/index.vue")
  },
  {
    name: "faq___ar___default",
    path: "/faq",
    meta: indexgbYO9sS11VMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/index.vue")
  },
  {
    name: "faq___ar",
    path: "/ar/faq",
    meta: indexgbYO9sS11VMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/faq/index.vue")
  },
  {
    name: "help-me-by-a-car-components-HeroSection___en",
    path: "/en/help-me-by-a-car/components/HeroSection",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/HeroSection.vue")
  },
  {
    name: "help-me-by-a-car-components-HeroSection___ar___default",
    path: "/help-me-by-a-car/components/HeroSection",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/HeroSection.vue")
  },
  {
    name: "help-me-by-a-car-components-HeroSection___ar",
    path: "/ar/help-me-by-a-car/components/HeroSection",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/HeroSection.vue")
  },
  {
    name: "help-me-by-a-car-components-SearchCar___en",
    path: "/en/help-me-by-a-car/components/SearchCar",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/SearchCar.vue")
  },
  {
    name: "help-me-by-a-car-components-SearchCar___ar___default",
    path: "/help-me-by-a-car/components/SearchCar",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/SearchCar.vue")
  },
  {
    name: "help-me-by-a-car-components-SearchCar___ar",
    path: "/ar/help-me-by-a-car/components/SearchCar",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/SearchCar.vue")
  },
  {
    name: "help-me-by-a-car-components-Specifications___en",
    path: "/en/help-me-by-a-car/components/Specifications",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/Specifications.vue")
  },
  {
    name: "help-me-by-a-car-components-Specifications___ar___default",
    path: "/help-me-by-a-car/components/Specifications",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/Specifications.vue")
  },
  {
    name: "help-me-by-a-car-components-Specifications___ar",
    path: "/ar/help-me-by-a-car/components/Specifications",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/components/Specifications.vue")
  },
  {
    name: "help-me-by-a-car___en",
    path: "/en/help-me-by-a-car",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/index.vue")
  },
  {
    name: "help-me-by-a-car___ar___default",
    path: "/help-me-by-a-car",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/index.vue")
  },
  {
    name: "help-me-by-a-car___ar",
    path: "/ar/help-me-by-a-car",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/help-me-by-a-car/index.vue")
  },
  {
    name: "impartiality-policy-components-Content___en",
    path: "/en/impartiality-policy/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/Content.vue")
  },
  {
    name: "impartiality-policy-components-Content___ar___default",
    path: "/impartiality-policy/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/Content.vue")
  },
  {
    name: "impartiality-policy-components-Content___ar",
    path: "/ar/impartiality-policy/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/Content.vue")
  },
  {
    name: "impartiality-policy-components-SinglePostHero___en",
    path: "/en/impartiality-policy/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/SinglePostHero.vue")
  },
  {
    name: "impartiality-policy-components-SinglePostHero___ar___default",
    path: "/impartiality-policy/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/SinglePostHero.vue")
  },
  {
    name: "impartiality-policy-components-SinglePostHero___ar",
    path: "/ar/impartiality-policy/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/components/SinglePostHero.vue")
  },
  {
    name: "impartiality-policy___en",
    path: "/en/impartiality-policy",
    meta: indexcwxzkSQZaLMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/index.vue")
  },
  {
    name: "impartiality-policy___ar___default",
    path: "/impartiality-policy",
    meta: indexcwxzkSQZaLMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/index.vue")
  },
  {
    name: "impartiality-policy___ar",
    path: "/ar/impartiality-policy",
    meta: indexcwxzkSQZaLMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/impartiality-policy/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/index.vue")
  },
  {
    name: "index___ar___default",
    path: "/",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/index.vue")
  },
  {
    name: "inspection-components-CarSearchBox___en",
    path: "/en/inspection/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/CarSearchBox.vue")
  },
  {
    name: "inspection-components-CarSearchBox___ar___default",
    path: "/inspection/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/CarSearchBox.vue")
  },
  {
    name: "inspection-components-CarSearchBox___ar",
    path: "/ar/inspection/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/CarSearchBox.vue")
  },
  {
    name: "inspection-components-ComparisonSlider___en",
    path: "/en/inspection/components/ComparisonSlider",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/ComparisonSlider.vue")
  },
  {
    name: "inspection-components-ComparisonSlider___ar___default",
    path: "/inspection/components/ComparisonSlider",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/ComparisonSlider.vue")
  },
  {
    name: "inspection-components-ComparisonSlider___ar",
    path: "/ar/inspection/components/ComparisonSlider",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/ComparisonSlider.vue")
  },
  {
    name: "inspection-components-Credits___en",
    path: "/en/inspection/components/Credits",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Credits.vue")
  },
  {
    name: "inspection-components-Credits___ar___default",
    path: "/inspection/components/Credits",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Credits.vue")
  },
  {
    name: "inspection-components-Credits___ar",
    path: "/ar/inspection/components/Credits",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Credits.vue")
  },
  {
    name: "inspection-components-Features___en",
    path: "/en/inspection/components/Features",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Features.vue")
  },
  {
    name: "inspection-components-Features___ar___default",
    path: "/inspection/components/Features",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Features.vue")
  },
  {
    name: "inspection-components-Features___ar",
    path: "/ar/inspection/components/Features",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Features.vue")
  },
  {
    name: "inspection-components-InspectionTypes___en",
    path: "/en/inspection/components/InspectionTypes",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/InspectionTypes.vue")
  },
  {
    name: "inspection-components-InspectionTypes___ar___default",
    path: "/inspection/components/InspectionTypes",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/InspectionTypes.vue")
  },
  {
    name: "inspection-components-InspectionTypes___ar",
    path: "/ar/inspection/components/InspectionTypes",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/InspectionTypes.vue")
  },
  {
    name: "inspection-components-Saas1Hero___en",
    path: "/en/inspection/components/Saas1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Saas1Hero.vue")
  },
  {
    name: "inspection-components-Saas1Hero___ar___default",
    path: "/inspection/components/Saas1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Saas1Hero.vue")
  },
  {
    name: "inspection-components-Saas1Hero___ar",
    path: "/ar/inspection/components/Saas1Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Saas1Hero.vue")
  },
  {
    name: "inspection-components-Work___en",
    path: "/en/inspection/components/Work",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Work.vue")
  },
  {
    name: "inspection-components-Work___ar___default",
    path: "/inspection/components/Work",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Work.vue")
  },
  {
    name: "inspection-components-Work___ar",
    path: "/ar/inspection/components/Work",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/components/Work.vue")
  },
  {
    name: "inspection-data___en",
    path: "/en/inspection/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/data.ts")
  },
  {
    name: "inspection-data___ar___default",
    path: "/inspection/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/data.ts")
  },
  {
    name: "inspection-data___ar",
    path: "/ar/inspection/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/data.ts")
  },
  {
    name: "inspection___en",
    path: "/en/inspection",
    meta: indexVX4G4X6CvjMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/index.vue")
  },
  {
    name: "inspection___ar___default",
    path: "/inspection",
    meta: indexVX4G4X6CvjMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/index.vue")
  },
  {
    name: "inspection___ar",
    path: "/ar/inspection",
    meta: indexVX4G4X6CvjMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspection/index.vue")
  },
  {
    name: "inspections-id___en",
    path: "/en/inspections/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/[id]/index.vue")
  },
  {
    name: "inspections-id___ar___default",
    path: "/inspections/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/[id]/index.vue")
  },
  {
    name: "inspections-id___ar",
    path: "/ar/inspections/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/[id]/index.vue")
  },
  {
    name: "inspections___en",
    path: "/en/inspections",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/index.vue")
  },
  {
    name: "inspections___ar___default",
    path: "/inspections",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/index.vue")
  },
  {
    name: "inspections___ar",
    path: "/ar/inspections",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/inspections/index.vue")
  },
  {
    name: "landing-mobile-app-1-assets-data-image___en",
    path: "/en/landing/mobile-app-1/assets/data/image",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/assets/data/image.ts")
  },
  {
    name: "landing-mobile-app-1-assets-data-image___ar___default",
    path: "/landing/mobile-app-1/assets/data/image",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/assets/data/image.ts")
  },
  {
    name: "landing-mobile-app-1-assets-data-image___ar",
    path: "/ar/landing/mobile-app-1/assets/data/image",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/assets/data/image.ts")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-AnchorNavigation___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-AnchorNavigation___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-AnchorNavigation___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/AnchorNavigation",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/AnchorNavigation.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Breadcrumb___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Breadcrumb___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Breadcrumb___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/Breadcrumb",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Breadcrumb.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-CustomSyntax___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-CustomSyntax___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-CustomSyntax___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/CustomSyntax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/CustomSyntax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Jarallax___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/Jarallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Jarallax___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/Jarallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Jarallax___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/Jarallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Jarallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LightGallery___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/LightGallery",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LightGallery___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/LightGallery",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LightGallery___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/LightGallery",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LightGallery.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LoadingSpinner___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LoadingSpinner___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-LoadingSpinner___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/LoadingSpinner",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/LoadingSpinner.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Parallax___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/Parallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Parallax___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/Parallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Parallax___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/Parallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Parallax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-PasswordInput___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/PasswordInput",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-PasswordInput___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/PasswordInput",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-PasswordInput___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/PasswordInput",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/PasswordInput.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Rellax___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/Rellax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Rellax___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/Rellax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-Rellax___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/Rellax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/Rellax.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VanillaTilt___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VanillaTilt___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VanillaTilt___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/VanillaTilt",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VanillaTilt.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VideoPopup___en",
    path: "/en/landing/mobile-app-1/ThemeComponents/VideoPopup",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VideoPopup___ar___default",
    path: "/landing/mobile-app-1/ThemeComponents/VideoPopup",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue")
  },
  {
    name: "landing-mobile-app-1-ThemeComponents-VideoPopup___ar",
    path: "/ar/landing/mobile-app-1/ThemeComponents/VideoPopup",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/landing/mobile-app-1/ThemeComponents/VideoPopup.vue")
  },
  {
    name: "news-id-components-ArticleCard___en",
    path: "/en/news/:id()/components/ArticleCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/ArticleCard.vue")
  },
  {
    name: "news-id-components-ArticleCard___ar___default",
    path: "/news/:id()/components/ArticleCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/ArticleCard.vue")
  },
  {
    name: "news-id-components-ArticleCard___ar",
    path: "/ar/news/:id()/components/ArticleCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/ArticleCard.vue")
  },
  {
    name: "news-id-components-Articles___en",
    path: "/en/news/:id()/components/Articles",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Articles.vue")
  },
  {
    name: "news-id-components-Articles___ar___default",
    path: "/news/:id()/components/Articles",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Articles.vue")
  },
  {
    name: "news-id-components-Articles___ar",
    path: "/ar/news/:id()/components/Articles",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Articles.vue")
  },
  {
    name: "news-id-components-Comment___en",
    path: "/en/news/:id()/components/Comment",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Comment.vue")
  },
  {
    name: "news-id-components-Comment___ar___default",
    path: "/news/:id()/components/Comment",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Comment.vue")
  },
  {
    name: "news-id-components-Comment___ar",
    path: "/ar/news/:id()/components/Comment",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Comment.vue")
  },
  {
    name: "news-id-components-CommentForm___en",
    path: "/en/news/:id()/components/CommentForm",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/CommentForm.vue")
  },
  {
    name: "news-id-components-CommentForm___ar___default",
    path: "/news/:id()/components/CommentForm",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/CommentForm.vue")
  },
  {
    name: "news-id-components-CommentForm___ar",
    path: "/ar/news/:id()/components/CommentForm",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/CommentForm.vue")
  },
  {
    name: "news-id-components-Content___en",
    path: "/en/news/:id()/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Content.vue")
  },
  {
    name: "news-id-components-Content___ar___default",
    path: "/news/:id()/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Content.vue")
  },
  {
    name: "news-id-components-Content___ar",
    path: "/ar/news/:id()/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Content.vue")
  },
  {
    name: "news-id-components-Jarallax___en",
    path: "/en/news/:id()/components/Jarallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Jarallax.vue")
  },
  {
    name: "news-id-components-Jarallax___ar___default",
    path: "/news/:id()/components/Jarallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Jarallax.vue")
  },
  {
    name: "news-id-components-Jarallax___ar",
    path: "/ar/news/:id()/components/Jarallax",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/Jarallax.vue")
  },
  {
    name: "news-id-components-LightGallery___en",
    path: "/en/news/:id()/components/LightGallery",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/LightGallery.vue")
  },
  {
    name: "news-id-components-LightGallery___ar___default",
    path: "/news/:id()/components/LightGallery",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/LightGallery.vue")
  },
  {
    name: "news-id-components-LightGallery___ar",
    path: "/ar/news/:id()/components/LightGallery",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/LightGallery.vue")
  },
  {
    name: "news-id-components-SinglePostFooter___en",
    path: "/en/news/:id()/components/SinglePostFooter",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostFooter.vue")
  },
  {
    name: "news-id-components-SinglePostFooter___ar___default",
    path: "/news/:id()/components/SinglePostFooter",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostFooter.vue")
  },
  {
    name: "news-id-components-SinglePostFooter___ar",
    path: "/ar/news/:id()/components/SinglePostFooter",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostFooter.vue")
  },
  {
    name: "news-id-components-SinglePostHero___en",
    path: "/en/news/:id()/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostHero.vue")
  },
  {
    name: "news-id-components-SinglePostHero___ar___default",
    path: "/news/:id()/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostHero.vue")
  },
  {
    name: "news-id-components-SinglePostHero___ar",
    path: "/ar/news/:id()/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/components/SinglePostHero.vue")
  },
  {
    name: "news-id-data___en",
    path: "/en/news/:id()/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/data.ts")
  },
  {
    name: "news-id-data___ar___default",
    path: "/news/:id()/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/data.ts")
  },
  {
    name: "news-id-data___ar",
    path: "/ar/news/:id()/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/data.ts")
  },
  {
    name: "news-id___en",
    path: "/en/news/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/index.vue")
  },
  {
    name: "news-id___ar___default",
    path: "/news/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/index.vue")
  },
  {
    name: "news-id___ar",
    path: "/ar/news/:id()",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/[id]/index.vue")
  },
  {
    name: "news-components-BlogCard___en",
    path: "/en/news/components/BlogCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/BlogCard.vue")
  },
  {
    name: "news-components-BlogCard___ar___default",
    path: "/news/components/BlogCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/BlogCard.vue")
  },
  {
    name: "news-components-BlogCard___ar",
    path: "/ar/news/components/BlogCard",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/BlogCard.vue")
  },
  {
    name: "news-components-Content___en",
    path: "/en/news/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Content.vue")
  },
  {
    name: "news-components-Content___ar___default",
    path: "/news/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Content.vue")
  },
  {
    name: "news-components-Content___ar",
    path: "/ar/news/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Content.vue")
  },
  {
    name: "news-components-Footer___en",
    path: "/en/news/components/Footer",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Footer.vue")
  },
  {
    name: "news-components-Footer___ar___default",
    path: "/news/components/Footer",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Footer.vue")
  },
  {
    name: "news-components-Footer___ar",
    path: "/ar/news/components/Footer",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Footer.vue")
  },
  {
    name: "news-components-Pagination___en",
    path: "/en/news/components/Pagination",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Pagination.vue")
  },
  {
    name: "news-components-Pagination___ar___default",
    path: "/news/components/Pagination",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Pagination.vue")
  },
  {
    name: "news-components-Pagination___ar",
    path: "/ar/news/components/Pagination",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/components/Pagination.vue")
  },
  {
    name: "news-data___en",
    path: "/en/news/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/data.ts")
  },
  {
    name: "news-data___ar___default",
    path: "/news/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/data.ts")
  },
  {
    name: "news-data___ar",
    path: "/ar/news/data",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/data.ts")
  },
  {
    name: "news___en",
    path: "/en/news",
    meta: index1mo0kUSEh3Meta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/index.vue")
  },
  {
    name: "news___ar___default",
    path: "/news",
    meta: index1mo0kUSEh3Meta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/index.vue")
  },
  {
    name: "news___ar",
    path: "/ar/news",
    meta: index1mo0kUSEh3Meta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/index.vue")
  },
  {
    name: "news-single-news___en",
    path: "/en/news/single-news",
    meta: single_45newsp7e5Henz1ZMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/single-news.vue")
  },
  {
    name: "news-single-news___ar___default",
    path: "/news/single-news",
    meta: single_45newsp7e5Henz1ZMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/single-news.vue")
  },
  {
    name: "news-single-news___ar",
    path: "/ar/news/single-news",
    meta: single_45newsp7e5Henz1ZMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/news/single-news.vue")
  },
  {
    name: "our-clients___en",
    path: "/en/our-clients",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/our-clients/index.vue")
  },
  {
    name: "our-clients___ar___default",
    path: "/our-clients",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/our-clients/index.vue")
  },
  {
    name: "our-clients___ar",
    path: "/ar/our-clients",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/our-clients/index.vue")
  },
  {
    name: "partners___en",
    path: "/en/partners",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/partners/index.vue")
  },
  {
    name: "partners___ar___default",
    path: "/partners",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/partners/index.vue")
  },
  {
    name: "partners___ar",
    path: "/ar/partners",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/partners/index.vue")
  },
  {
    name: "payment-redirect___en",
    path: "/en/payment/redirect",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/payment/redirect/index.vue")
  },
  {
    name: "payment-redirect___ar___default",
    path: "/payment/redirect",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/payment/redirect/index.vue")
  },
  {
    name: "payment-redirect___ar",
    path: "/ar/payment/redirect",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/payment/redirect/index.vue")
  },
  {
    name: "privacy-policy-components-Content___en",
    path: "/en/privacy-policy/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/Content.vue")
  },
  {
    name: "privacy-policy-components-Content___ar___default",
    path: "/privacy-policy/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/Content.vue")
  },
  {
    name: "privacy-policy-components-Content___ar",
    path: "/ar/privacy-policy/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/Content.vue")
  },
  {
    name: "privacy-policy-components-SinglePostHero___en",
    path: "/en/privacy-policy/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/SinglePostHero.vue")
  },
  {
    name: "privacy-policy-components-SinglePostHero___ar___default",
    path: "/privacy-policy/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/SinglePostHero.vue")
  },
  {
    name: "privacy-policy-components-SinglePostHero___ar",
    path: "/ar/privacy-policy/components/SinglePostHero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/components/SinglePostHero.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    meta: index3Yy8Mp8rERMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___ar___default",
    path: "/privacy-policy",
    meta: index3Yy8Mp8rERMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___ar",
    path: "/ar/privacy-policy",
    meta: index3Yy8Mp8rERMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/privacy-policy/index.vue")
  },
  {
    name: "sales-agent-form___en",
    path: "/en/sales-agent-form",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/sales-agent-form/index.vue")
  },
  {
    name: "sales-agent-form___ar___default",
    path: "/sales-agent-form",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/sales-agent-form/index.vue")
  },
  {
    name: "sales-agent-form___ar",
    path: "/ar/sales-agent-form",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/sales-agent-form/index.vue")
  },
  {
    name: "success___en",
    path: "/en/success",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/success/index.vue")
  },
  {
    name: "success___ar___default",
    path: "/success",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/success/index.vue")
  },
  {
    name: "success___ar",
    path: "/ar/success",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/success/index.vue")
  },
  {
    name: "survey___en",
    path: "/en/survey",
    meta: indext3NlonbZrkMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/survey/index.vue")
  },
  {
    name: "survey___ar___default",
    path: "/survey",
    meta: indext3NlonbZrkMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/survey/index.vue")
  },
  {
    name: "survey___ar",
    path: "/ar/survey",
    meta: indext3NlonbZrkMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/survey/index.vue")
  },
  {
    name: "terms-and-condition-components-Content___en",
    path: "/en/terms-and-condition/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/components/Content.vue")
  },
  {
    name: "terms-and-condition-components-Content___ar___default",
    path: "/terms-and-condition/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/components/Content.vue")
  },
  {
    name: "terms-and-condition-components-Content___ar",
    path: "/ar/terms-and-condition/components/Content",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/components/Content.vue")
  },
  {
    name: "terms-and-condition___en",
    path: "/en/terms-and-condition",
    meta: indexMqaIJBSmczMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/index.vue")
  },
  {
    name: "terms-and-condition___ar___default",
    path: "/terms-and-condition",
    meta: indexMqaIJBSmczMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/index.vue")
  },
  {
    name: "terms-and-condition___ar",
    path: "/ar/terms-and-condition",
    meta: indexMqaIJBSmczMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/terms-and-condition/index.vue")
  },
  {
    name: "trailer-inspection-and-half-trailers___en",
    path: "/en/trailer-inspection-and-half-trailers",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/trailer-inspection-and-half-trailers/index.vue")
  },
  {
    name: "trailer-inspection-and-half-trailers___ar___default",
    path: "/trailer-inspection-and-half-trailers",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/trailer-inspection-and-half-trailers/index.vue")
  },
  {
    name: "trailer-inspection-and-half-trailers___ar",
    path: "/ar/trailer-inspection-and-half-trailers",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/trailer-inspection-and-half-trailers/index.vue")
  },
  {
    name: "trim___en",
    path: "/en/trim",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/trim/index.vue")
  },
  {
    name: "trim___ar___default",
    path: "/trim",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/trim/index.vue")
  },
  {
    name: "trim___ar",
    path: "/ar/trim",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/trim/index.vue")
  },
  {
    name: "vin-decoder___en",
    path: "/en/vin-decoder",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/vin-decoder/index.vue")
  },
  {
    name: "vin-decoder___ar___default",
    path: "/vin-decoder",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/vin-decoder/index.vue")
  },
  {
    name: "vin-decoder___ar",
    path: "/ar/vin-decoder",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/vin-decoder/index.vue")
  },
  {
    name: "warranties___en",
    path: "/en/warranties",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranties/index.vue")
  },
  {
    name: "warranties___ar___default",
    path: "/warranties",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranties/index.vue")
  },
  {
    name: "warranties___ar",
    path: "/ar/warranties",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranties/index.vue")
  },
  {
    name: "warranty-form-autohub-components-Pricing___en",
    path: "/en/warranty-form-autohub/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Pricing.vue")
  },
  {
    name: "warranty-form-autohub-components-Pricing___ar___default",
    path: "/warranty-form-autohub/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Pricing.vue")
  },
  {
    name: "warranty-form-autohub-components-Pricing___ar",
    path: "/ar/warranty-form-autohub/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Pricing.vue")
  },
  {
    name: "warranty-form-autohub-components-PricingForMobile___en",
    path: "/en/warranty-form-autohub/components/PricingForMobile",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/PricingForMobile.vue")
  },
  {
    name: "warranty-form-autohub-components-PricingForMobile___ar___default",
    path: "/warranty-form-autohub/components/PricingForMobile",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/PricingForMobile.vue")
  },
  {
    name: "warranty-form-autohub-components-PricingForMobile___ar",
    path: "/ar/warranty-form-autohub/components/PricingForMobile",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/PricingForMobile.vue")
  },
  {
    name: "warranty-form-autohub-components-Saas3Hero___en",
    path: "/en/warranty-form-autohub/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Saas3Hero.vue")
  },
  {
    name: "warranty-form-autohub-components-Saas3Hero___ar___default",
    path: "/warranty-form-autohub/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Saas3Hero.vue")
  },
  {
    name: "warranty-form-autohub-components-Saas3Hero___ar",
    path: "/ar/warranty-form-autohub/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/Saas3Hero.vue")
  },
  {
    name: "warranty-form-autohub-components-WarrantyForm___en",
    path: "/en/warranty-form-autohub/components/WarrantyForm",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/WarrantyForm.vue")
  },
  {
    name: "warranty-form-autohub-components-WarrantyForm___ar___default",
    path: "/warranty-form-autohub/components/WarrantyForm",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/WarrantyForm.vue")
  },
  {
    name: "warranty-form-autohub-components-WarrantyForm___ar",
    path: "/ar/warranty-form-autohub/components/WarrantyForm",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/components/WarrantyForm.vue")
  },
  {
    name: "warranty-form-autohub___en",
    path: "/en/warranty-form-autohub",
    meta: indexVBskuRoFOlMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/index.vue")
  },
  {
    name: "warranty-form-autohub___ar___default",
    path: "/warranty-form-autohub",
    meta: indexVBskuRoFOlMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/index.vue")
  },
  {
    name: "warranty-form-autohub___ar",
    path: "/ar/warranty-form-autohub",
    meta: indexVBskuRoFOlMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form-autohub/index.vue")
  },
  {
    name: "warranty-form___en",
    path: "/en/warranty-form",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form/index.vue")
  },
  {
    name: "warranty-form___ar___default",
    path: "/warranty-form",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form/index.vue")
  },
  {
    name: "warranty-form___ar",
    path: "/ar/warranty-form",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty-form/index.vue")
  },
  {
    name: "warranty-components-CarSearchBox___en",
    path: "/en/warranty/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/CarSearchBox.vue")
  },
  {
    name: "warranty-components-CarSearchBox___ar___default",
    path: "/warranty/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/CarSearchBox.vue")
  },
  {
    name: "warranty-components-CarSearchBox___ar",
    path: "/ar/warranty/components/CarSearchBox",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/CarSearchBox.vue")
  },
  {
    name: "warranty-components-Pricing___en",
    path: "/en/warranty/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Pricing.vue")
  },
  {
    name: "warranty-components-Pricing___ar___default",
    path: "/warranty/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Pricing.vue")
  },
  {
    name: "warranty-components-Pricing___ar",
    path: "/ar/warranty/components/Pricing",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Pricing.vue")
  },
  {
    name: "warranty-components-PricingForMobile___en",
    path: "/en/warranty/components/PricingForMobile",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/PricingForMobile.vue")
  },
  {
    name: "warranty-components-PricingForMobile___ar___default",
    path: "/warranty/components/PricingForMobile",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/PricingForMobile.vue")
  },
  {
    name: "warranty-components-PricingForMobile___ar",
    path: "/ar/warranty/components/PricingForMobile",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/PricingForMobile.vue")
  },
  {
    name: "warranty-components-Saas3Hero___en",
    path: "/en/warranty/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Saas3Hero.vue")
  },
  {
    name: "warranty-components-Saas3Hero___ar___default",
    path: "/warranty/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Saas3Hero.vue")
  },
  {
    name: "warranty-components-Saas3Hero___ar",
    path: "/ar/warranty/components/Saas3Hero",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/Saas3Hero.vue")
  },
  {
    name: "warranty-components-WarrantyFeature___en",
    path: "/en/warranty/components/WarrantyFeature",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/WarrantyFeature.vue")
  },
  {
    name: "warranty-components-WarrantyFeature___ar___default",
    path: "/warranty/components/WarrantyFeature",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/WarrantyFeature.vue")
  },
  {
    name: "warranty-components-WarrantyFeature___ar",
    path: "/ar/warranty/components/WarrantyFeature",
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/components/WarrantyFeature.vue")
  },
  {
    name: "warranty___en",
    path: "/en/warranty",
    meta: indexVh6E3E8qOJMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/index.vue")
  },
  {
    name: "warranty___ar___default",
    path: "/warranty",
    meta: indexVh6E3E8qOJMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/index.vue")
  },
  {
    name: "warranty___ar",
    path: "/ar/warranty",
    meta: indexVh6E3E8qOJMeta || {},
    component: () => import("/home/spectertrustcar5/public_html/cartrust_website/pages/warranty/index.vue")
  },
  {
    name: component_45stubrYY2lHF0KfMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubrYY2lHF0Kf
  }
]